import * as Sentry from '@sentry/capacitor'
import * as SentryVue from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  Sentry.init(
    {
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [
        SentryVue.browserTracingIntegration({
          router,
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', /^https:\/\/almana\.cc/],
        }),
        SentryVue.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: sentry.tracesSampleRate,

      replaysSessionSampleRate: sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
    },
    // Forward the init method from @sentry/vue
    SentryVue.init,
  )
})
